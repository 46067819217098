import { Button, Modal, InputText, Alert, Form, Table, DropDown } from '@components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import {
  Navigation,
  SubNavigation,
  Section,
  NetworkNotification,
  ConfirmationModal,
  SectionButtons
} from '@layouts';
import { Dialog } from '@headlessui/react';
import moment from 'moment';
import queries from '@queries';
import { status } from '@root/config';
import { usePersistMutation } from '@hooks';
import uuid from 'react-uuid';
import { sortAlphabeticallyHuman } from '@root/utils';

export const checkPolicy = (item) => {
  const eventdate = moment(item?.expiryDate);
  const todaysdate = moment();
  const daysLeft = eventdate.diff(todaysdate, 'days');
  return daysLeft > item?.article?.policy * 30 ? 3 : daysLeft >= 0 ? 2 : 1;
};

function ContainerEdit() {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteContainerMutation = usePersistMutation(
    ['containers'],
    (data) => {
      return queries.delete('/api/containers', data);
    },
    () => {
      setOpenModalDeleteConfirmation(false);
      navigate(-1);
    },
    () => containerId,
    'isDelete'
  );

  const updateContainerMutation = usePersistMutation(
    ['containers'],
    (data) => queries.put('/api/containers', data),
    () => queryClient.invalidateQueries('containers'),
    (variables) => {
      return {
        ...dataContainers?.data?.filter((c) => c.id === containerId)[0],
        name: variables[0].name
      };
    },
    'isUpdate'
  );

  const { containerId = 1 } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { data: dataContainers, isLoading: dataContainersLoading } = useQuery(['containers'], () =>
    queries.getAll('/api/containers', {
      populate: ['medicines', 'medicines.article']
    })
  );

  //medicine
  const [currentMedicine, setCurrentMedicine] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModalDeleteConfirmation, setOpenModalDeleteConfirmation] = useState(false);

  const createMedicinesDuplicateMutation = usePersistMutation(
    ['containers'],
    (data) => {
      return queries.post('/api/medicines', data);
    },
    () => setOpenModal(false),
    (variables) => {
      return {
        ...dataContainers?.data?.filter((c) => c.id === containerId)[0],
        id: uuid(),
        medicines: dataContainers?.data
          ?.filter((c) => c.id === containerId)[0]
          .medicines.push({
            ...variables[0],
            id: uuid(),
            article: articles?.data?.filter((a) => a.id === parseInt(variables[0].article))[0]
          })
      };
    },
    true
  );

  const { data: articles } = useQuery(['articles'], () =>
    queries.getAll('/api/articles', { sort: ['name:asc'] })
  );

  const deleteMedicineMutation = useMutation((data) => queries.delete('/api/medicines', data));

  const deleteMedecines = async () => {
    for (
      let i = 0;
      i < dataContainers?.data?.filter((c) => c.id === containerId)[0]?.medicines.length;
      i++
    ) {
      await deleteMedicineMutation.mutateAsync([
        dataContainers?.data?.filter((c) => c.id === containerId)[0]?.medicines[i]
      ]);
    }
  };

  if (dataContainersLoading) return <></>;

  return (
    <div className="h-full min-h-screen flex flex-col bg-gray-100">
      <ConfirmationModal
        id={containerId}
        isOpen={openModalDeleteConfirmation}
        setIsOpen={setOpenModalDeleteConfirmation}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isError={isError}
        setIsError={setIsError}
        deleteMutation={deleteContainerMutation}
        callback={deleteMedecines}
        invalidateQueries="containers"
      />
      <Navigation />
      <SubNavigation active="Contenants" state={location.state} />
      <NetworkNotification />

      <Form
        mutation={updateContainerMutation}
        setIsError={setIsError}
        setIsLoading={setIsLoading}
        callback={() => {
          queryClient.invalidateQueries('containers');
        }}>
        <div className="space-y-6 px-4 sm:px-6 lg:px-8">
          {isError && (
            <Alert color="red" className="mb-4 shadow-sm mt-4">
              Impossible de modifier le contenant.
              <br />
              Assurez-vous que les informations soient correctement renseignées.
            </Alert>
          )}
          <input
            name="id"
            type="hidden"
            value={dataContainers?.data?.filter((c) => c?.id === containerId)[0]?.id}
          />
          <Section
            title="Contenant"
            button={
              <SectionButtons
                queryKey="containers"
                setIsOpen={setOpenModalDeleteConfirmation}
                isError={isError}
                isLoading={isLoading}
              />
            }>
            <InputText
              label="Nom"
              name="name"
              classNameInput={'rounded'}
              defaultValue={dataContainers?.data?.filter((c) => c?.id === containerId)[0]?.name}
              required={true}
            />
          </Section>
        </div>
      </Form>

      {/* medicine */}
      <div className="space-y-6 px-4 sm:px-6 lg:px-8 py-6">
        <Section title="Rechercher un medicaments dans la trousse">
          {/*  eslint-disable-next-line react/no-unknown-property */}
          <div parentClassName="sm:col-span-4" className="grid grid-cols-4 gap-6">
            <div className="col-span-4 sm:col-span-3">
              <InputText
                value={currentMedicine}
                onChange={(e) => setCurrentMedicine(e.target.value)}
                placeholder="Rechercher un medicament dans le contenant"
              />
            </div>
            <div className="col-span-4 sm:col-span-1 flex justify-end items-end">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenModal(true);
                }}>
                + Nouveau medicament
              </Button>
            </div>
          </div>
        </Section>
        <Section title="Listes des medicaments">
          <Table
            className="mt-0"
            data={dataContainers?.data
              ?.filter((c) => c?.id === containerId)[0]
              ?.medicines?.filter((o) =>
                o?.article?.name?.toLowerCase()?.includes(currentMedicine?.toLowerCase())
              )
              ?.sort((a, b) => sortAlphabeticallyHuman(a?.article?.name, b?.article?.name))
              ?.sort((a, b) => (checkPolicy(a) ?? 0) - (checkPolicy(b) ?? 0))
              .map((a) => ({
                type: a?.article?.name,
                status: (
                  <span
                    className={`inline-flex items-center rounded-full bg-${
                      status[checkPolicy(a)]?.color
                    }-100 px-2.5 py-0.5 text-xs font-medium text-${
                      status[checkPolicy(a)]?.color
                    }-800`}>
                    {status[checkPolicy(a)]?.name}
                  </span>
                ),
                date: a?.expiryDate ? moment(a?.expiryDate).format('DD/MM/YYYY') : ''
              }))}
            labels={['Type', 'Statut', 'Date de péremption']}
            onClick={(index) =>
              navigate('/medicines-edit', {
                state: {
                  ...location.state,
                  medicineId: dataContainers?.data
                    ?.filter((c) => c.id === containerId)[0]
                    ?.medicines?.filter((o) =>
                      o?.article?.name?.toLowerCase()?.includes(currentMedicine?.toLowerCase())
                    )
                    ?.sort((a, b) => sortAlphabeticallyHuman(a?.article?.name, b?.article?.name))
                    ?.sort((a, b) => (checkPolicy(a) ?? 0) - (checkPolicy(b) ?? 0))[index].id
                }
              })
            }
            parentClassName="sm:col-span-4"
          />
        </Section>
      </div>
      <Modal isOpen={openModal} setIsOpen={setOpenModal}>
        <Form
          mutation={createMedicinesDuplicateMutation}
          setIsError={setIsError}
          setIsLoading={setIsLoading}
          callback={() => {
            queryClient.invalidateQueries('medicine');
            setOpenModal(false);
          }}>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-0 text-left">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Créer un medicament
              </Dialog.Title>
              {isError && (
                <Alert color="red" className="mb-4 mt-2">
                  Impossible de créer un medicament.
                  <br />
                  Assurez-vous d&apos;être connecté à internet
                </Alert>
              )}
              <InputText className="mt-3" name="container" type="hidden" value={containerId} />
              <DropDown
                className="mt-3 w-full"
                label="Type"
                name="article"
                data={articles?.data?.map((a) => ({
                  label: a?.name,
                  value: a?.id
                }))}
                required={true}
              />
              <InputText
                className="mt-3"
                name="expiryDate"
                label="Date de péremption*"
                type="date"
                required={true}
              />
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-row justify-between">
            <Button
              type="button"
              onClick={() => setOpenModal(false)}
              color="white"
              className="border border-gray-300 text-gray-700 hover:bg-gray-50 bg-white">
              Annuler
            </Button>
            <Button
              type="submit"
              color="green"
              onClick={() => {}}
              isLoading={isLoading}
              isWrong={isError}>
              Créer
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default ContainerEdit;
